export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/players/list',
    name: 'apps-players-list',
    component: () => import('@/views/players/players-list/PlayersList.vue'),
    meta: {
      resource: 'PLAYERS',
      action: 'view',
    },
  },
  
  {
    path: '/apps/players/view/:id',
    name: 'apps-players-view',
    component: () => import('@/views/players/player-view/PlayerView.vue'),
    meta: {
      resource: 'PLAYERS',
      action: 'view',
    },
  },
]
