export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/products/list',
    name: 'apps-products-list',
    component: () => import('@/views/products/products-list/ProductsList.vue'),
    meta: {
      resource: 'PRODUCTS',
      action: 'read',
    },
  },
  // {
  //   path: '/apps/products/view/:id',
  //   name: 'apps-products-view',
  //   component: () => import('@/views/products/products-view/ProductsView.vue'),
  // },
  {
    path: '/apps/products/edit/:id',
    name: 'apps-products-edit',
    component: () => import('@/views/products/products-edit/ProductsEdit.vue'),
    meta: {
      resource: 'PRODUCTS',
      action: 'edit',
    },
  },
]
