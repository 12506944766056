export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/bets/list',
    name: 'apps-bets-list',
    component: () => import('@/views/bets/bets-list/BetsList.vue'),
    meta: {
      resource: 'BETS',
      action: 'view',
    },
  },
]
