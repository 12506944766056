export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/games/list',
    name: 'apps-games-list',
    component: () => import('@/views/games/games-list/GamesList.vue'),
    meta: {
      resource: 'view',
      action: 'GAMES',
    },
  },
  // {
  //   path: '/apps/games/view/:id',
  //   name: 'apps-games-view',
  //   component: () => import('@/views/game/games-view/GamesView.vue'),
  //   meta: {
  //     resource: 'view',
  //     action: 'GAMES',
  //   },
  // },
  // {
  //   path: '/apps/games/edit/:id',
  //   name: 'apps-games-edit',
  //   component: () => import('@/views/game/games-edit/GamesEdit.vue'),
  //   meta: {
  //     resource: 'edit',
  //     action: 'GAMES',
  //   },
  // },
]
