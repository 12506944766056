export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/stats/analytics',
    name: 'apps-stats-graphs',
    component: () => import('@/views/stats/home/Charts.vue'),
    meta: {
      resource: 'STATS',
      action: 'view',
    },
  },
]
