export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/payins/list',
    name: 'apps-payins-list',
    component: () => import('@/views/payments/payins-list/PayinList.vue'),
    meta: {
      resource: 'PAYINS',
      action: 'view',
    },
  },
  {
    path: '/apps/payouts/list',
    name: 'apps-payouts-list',
    component: () => import('@/views/payments/payouts-list/PayoutList.vue'),
    meta: {
      resource: 'PAYOUTS',
      action: 'view',
    },
  },

]
