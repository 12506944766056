export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      resource: 'view',
      action: 'TEAM',
    },
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/user/users-view/UsersView.vue'),
    meta: {
      resource: 'view',
      action: 'TEAM',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'edit',
      action: 'TEAM',
    },
  },
]
