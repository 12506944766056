import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import authentication from './authentication'
import users from './users'
import bets from './bets'
import payments from './payments'
import products from './products'
import miscellaneous from './miscellaneous'
import players from './players'
import stats from './stats'
import games from './games'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/stats/home/Home.vue'),
      meta: {
        resource: 'STATS',
        action: 'view',
      },
      // meta: {
      //   pageTitle: 'Home',
      //   breadcrumb: [
      //     {
      //       text: 'Summary Stats',
      //       active: true,
      //     },
      //   ],
      // },
    },
    ...authentication,
    ...users,
    ...bets,
    ...products,
    ...miscellaneous,
    ...payments,
    ...players,
    ...stats,
    ...games,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
/// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.access_level : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
